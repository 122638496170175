import { gsap } from "gsap";
import redHole from "../assets/images/redHole.webp";
import ornaments from "../assets/images/ornaments.webp";
import hollow from "../assets/images/hollow.webp";
function Loading() {
  var tl = gsap.timeline({ repeat: 2, repeatDelay: 0.2 });
  tl.to("#hollow", { x: 100, duration: 1 });

  return (
    <div className="h-screen relative snap-start bg-cream overflow-hidden z-50">
      {/* Images */}
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <img
          className="pointer-events-none min-w-[180rem] min-h-[180rem]  p-9 z-0 animate-spin-slow"
          src={ornaments}
          alt="Ornaments"
        />
      </div>
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <img
          className="pointer-events-none min-h-[25rem] min-w-[25rem]  p-9 z-50 animate-spin-fast"
          src={redHole}
          alt="Red Hole Spin"
        />
      </div>

      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <img
          id="hollow"
          className="pointer-events-none min-h-[45rem] min-w-[45rem] p-9 z-10"
          src={hollow}
          alt="Hollow"
        />
      </div>
    </div>
  );
}

export default Loading;
