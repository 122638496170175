import left from "../assets/images/left.webp";
import right from "../assets/images/right.webp";
import star from "../assets/star.svg";
import instagram from "../assets/instagram.svg";
import twitter from "../assets/twitter.svg";
import youtube from "../assets/youtube.svg";

function Socials() {
  return (
    <div className="h-dvh relative snap-start z-20">
      <header className="h-dvh absolute left-1/2 transform -translate-x-1/2 w-[95%]">
        <div>
          <div className="absolute top-0 md:top-auto md:bottom-0  left-1/2 transform -translate-x-1/2 size-96 md:size-[35rem] bg-crimson rounded-full md:-translate-x-0 md:left-0 my-9 pointer-events-none z-0"></div>
          <img
            className="absolute top-0 md:top-auto md:bottom-0 left-1/2 transform -translate-x-1/2 md:translate-x-0 md:left-0 rotate-180 md:rotate-0 w-[60%] md:w-[38%] z-10 pointer-events-none"
            src={left}
            alt="ADHD Female"
          />
        </div>
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
          <div className="flex md:flex-col justify-center gap-9 z-50">
            <a
              href="https://twitter.com/bygretya/"
              className="relative size-24 hover:shadow-xl hover:shadow-blax bg-crimson hover:bg-blax rounded-full z-50"
            >
              <img
                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-6 invert"
                src={twitter}
                alt="Twitter icon"
              />
            </a>
            <a
              href="https://instagram.com/bygretya"
              className="relative size-24 hover:shadow-xl hover:shadow-crimson bg-blax hover:bg-crimson rounded-full z-50"
            >
              <img
                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-6 invert"
                src={instagram}
                alt="Instagram icon"
              />
            </a>
            <a
              href="https://www.youtube.com/@bygretya2163"
              className="relative size-24 hover:shadow-xl hover:shadow-blax bg-crimson hover:bg-blax rounded-full z-50"
            >
              <img
                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-6 invert"
                src={youtube}
                alt="Youtube icon"
              />
            </a>
          </div>
        </div>
        <div>
          <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 size-96 md:size-[30rem] bg-crimson rounded-full md:translate-x-0 md:left-auto md:right-0 my-9 pointer-events-none z-0"></div>
          <img
            className="absolute bottom-0 left-1/2 transform -translate-x-1/2 md:translate-x-0 md:left-auto md:right-0 w-[70%] md:w-[45%] z-10 pointer-events-none"
            src={right}
            alt="Autism Female"
          />
        </div>
        <img
          className="absolute invisible md:visible left-1/2 transform -translate-x-1/2 top-0 my-12"
          src={star}
          alt="Star Vector"
        />
      </header>
    </div>
  );
}
export default Socials;
