import logo from "../assets/logo.svg";
import arrow from "../assets/arrow.svg";
import spikeHead from "../assets/images/spikeHead.webp";
function Links() {
  return (
    <div className="h-dvh relative snap-start z-20">
      <header className="h-dvh absolute left-1/2 transform -translate-x-1/2 w-[95%]">
        <div className="absolute top-0 left-1/2 md:left-0 transform -translate-x-1/2 md:-translate-x-0 p-3 mx-auto md:mx-0  ">
          <div className="flex">
            <img className="m-3 w-2/3" src={logo} alt="" />
            <h3 className="font-thin ">/ Links</h3>
          </div>
        </div>
        {/* Link Grid */}
        <div className="absolute top-1/3 md:top-1/2 mt-24 left-1/2 md:left-0 transform -translate-x-1/2 -translate-y-1/2 md:translate-x-0 md:mx-6 my-9 ">
          <hr className="border border-red-400 my-3" />
          <div class="flex flex-col md:flex-row gap-9">
            <a
              className="bg-cream hover:bg-red-400 transition-colors duration-300"
              href="https://ko-fi.com/bygretya"
            >
              <div className="w-[200px] h-[200px] border border-blax p-2 relative shadow-md">
                <h3 className="font-light text-xs">/ 01</h3>
                <h1 className="font-bold text-xl my-3">Ko-Fi</h1>
                <img
                  className="absolute top-0 right-0 p-3 size-16"
                  src={arrow}
                  alt="Arrow One"
                />
                <p className="absolute bottom-0 right-0 p-3 text-right text-xs font-light">
                  Here you can support me.
                </p>
              </div>
            </a>
            <a
              className="bg-cream hover:bg-blue-400 transition-colors duration-300"
              href="https://ko-fi.com/bygretya/shop"
            >
              <div className="w-[200px] h-[200px] border border-blax p-2 relative shadow-md">
                <h3 className="font-light text-xs">/ 02</h3>
                <h1 className="font-bold text-xl my-3">Ko-Fi Shop</h1>
                <img
                  className="absolute top-0 right-0 p-3 size-16"
                  src={arrow}
                  alt="Arrow Two"
                />
                <p className="absolute bottom-0 right-0 p-3 text-right text-xs font-light">
                  Here you can support me / purchase stickers and prints.
                </p>
              </div>
            </a>
          </div>
          <hr className="border border-blue-400 my-3" />
        </div>

        <div className="">
          <img
            className="absolute bottom-0 left-1/2 md:left-auto md:right-0 transform -translate-x-1/2 md:-translate-x-0 mx-auto md:mx-3 w-1/2 md:w-1/3 pointer-events-none"
            src={spikeHead}
            alt="Spike Head Girl"
          />
        </div>
        <div className="absolute bottom-0 left-0 m-3">
          <div class="grid grid-rows-2 grid-flow-col gap-4 font-light">
            <div className="text-blax">01</div>
            <div className="text-blax">01</div>
            <div className="text-blue-400">09</div>
            <div className="text-blax">02</div>
            <div className="text-blax">01</div>
            <div className="text-red-400">03</div>
            <div className="text-blax">01</div>
            <div className="text-blax">07</div>
          </div>
        </div>
      </header>
    </div>
  );
}
export default Links;
