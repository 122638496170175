// import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Enter from "./components/enter.jsx";
import Loading from "./components/loading.jsx";
import MainPage from "./components/mainPage.jsx";
import Gallery from "./components/gallery.jsx";
import Cheburashka from "./components/cheburashka.jsx";
import Links from "./components/links.jsx";
import Socials from "./components/socials.jsx";
import End from "./components/end.jsx";
import arrow from "./assets/arrow.svg";
function App() {
  // const [loading, setLoading] = useState(false);
  // useEffect(() => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 5000);
  // }, []);
  return (
    <div className="font-montserrat">
      <section className="snap-y snap-mandatory snap-always scroll-smooth h-dvh overflow-scroll overflow-x-hidden bg-cream">
        <Enter />
        <MainPage />
        <Gallery />
        <Links />
        <Cheburashka />
        <Socials />
        <End />
        <img
          className="absolute z-10 top-0 right-0 m-6 size-6"
          src={arrow}
          alt=""
        />
        <img
          className="absolute scale-x-[-1] z-10 top-0 left-0 m-6 size-6"
          src={arrow}
          alt=""
        />
      </section>

      {/* <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/Lab" element={<Lab />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </BrowserRouter> */}
    </div>
  );
}

export default App;
