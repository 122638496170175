import endLetter from "../assets/images/endLetter.webp";
import star from "../assets/star.svg";

function End() {
  return (
    <div className="h-dvh relative snap-start z-50">
      <header className="h-dvh absolute left-1/2 transform -translate-x-1/2 w-[95%]">
        <img
          className="absolute bottom-0 right-0 md:w-[35%] pointer-events-none z-50"
          src={endLetter}
          alt="End Girl 2022"
        />
        {/* QR code here */}
        <img
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 size-56 pointer-events-none z-40"
          src={star}
          alt=""
        />
        {/* Border */}
        <div className="absolute top-0 md:bottom-0 md:top-auto left-1/2 transform -translate-x-1/2 w-full h-[50%] md:h-[30%] border-2 shadow-sm bg-cream border-blax rounded-b-xl border-t-0 md:border-t-2 md:border-b-0 md:rounded-t-xl md:rounded-b-none z-30">
          {/* Text */}
          <div className="absolute p-9 top-0 md:top-auto md:bottom-0 text-blax font-light left-0 m-3">
            <p className="text-sm">2024</p>
            <p className="text-sm">Lithuania / Kaunas</p>
            <h1 className="">art@bygretya.moe / @bygretya</h1>
            <div className="md:w-[55%]">
              <hr className="bg-blax border-blax my-3" />
              <p className="text-sm ">
                Hey! My name’s Greta and I’m a self taught digital artist from
                Lithuania. Art is currently just my hobby, but I’d love to build
                something out of it with your help ofcourse !
              </p>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}
export default End;
