import chLeft from "../assets/images/chLeft.webp";
import chRight from "../assets/images/chRight.webp";
import redHole from "../assets/images/redHole.webp";
import outerCircle from "../assets/outerCircle.svg";
function Cheburashka() {
  return (
    <div className="h-dvh relative snap-start z-10 overflow-ellipsis">
      {/* Images */}

      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <img
          className="pointer-events-none min-w-[180rem] min-h-[180rem] lg:h-[35rem] p-9 z-0 animate-spin-slow"
          src={outerCircle}
          alt="Out Star Circle"
        />
      </div>
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <img
          className="pointer-events-none min-h-[50rem] min-w-[50rem] p-9 z-50 animate-ping"
          src={redHole}
          alt="Red Hole Ping"
        />
      </div>
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <img
          className="pointer-events-none min-h-[50rem] min-w-[50rem] p-9 z-50 animate-spin-medium"
          src={redHole}
          alt="Red Hole Spin"
        />
      </div>

      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <img
          className="pointer-events-none min-h-[45rem] min-w-[45rem] lg:h-[35rem] p-9 z-10"
          src={chLeft}
          alt="Light Cheburashka"
        />
      </div>

      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <img
          className="pointer-events-none min-h-[45rem] min-w-[45rem] lg:h-[35rem] p-9 z-10"
          src={chRight}
          alt="Dark Cheburashka"
        />
      </div>
    </div>
  );
}
export default Cheburashka;
