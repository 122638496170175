import "../gallery.css";

function Gallery() {
  return (
    <div className="h-dvh relative snap-start z-20">
      <header className="h-dvh absolute left-1/2 transform -translate-x-1/2 w-[95%]">
        <h1 className="p-3 m-9 text-3xl font-bold">
          <span className="font-light text-sm">bygretya /</span> Gallery
        </h1>
        <div
          class="container"
          className="md:skew-x-6 md:skew-y-3 p-3 md:m-12 md:mx-32 border border-blax"
        >
          <div class="gallery-wrap">
            <div class="item item-1"></div>
            <div class="item item-2"></div>
            <div class="item item-3"></div>
            <div class="item item-4"></div>
            <div class="item item-5"></div>
            <div class="item item-6"></div>
          </div>
        </div>
        <h3 className="absolute left-0 bottom-0 p-6">® 2024 / bygretya</h3>
      </header>
    </div>
  );
}
export default Gallery;
