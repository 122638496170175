import { gsap } from "gsap";
import { useRef, useLayoutEffect } from "react";
function Enter() {
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      var t1 = gsap.timeline({ repeat: -1 });
      t1.from("#logo", {
        y: "-50",
        stagger: "0.05",
        duration: 1,
      });
      t1.to("#logo", {
        y: "-50",
        stagger: "0.05",
        duration: 1,
      });
      var t2 = gsap.timeline();
      t2.from("#text-holder", {
        xPercent: "-300",
        duration: 1,
      });
      t2.from("#bgr", {
        yPercent: "-75",
        stagger: "0.07",
        duration: 0.75,
        opacity: "0",
      });
      t2.to("#bgr", {
        yPercent: "-75",
        stagger: "-0.07",
        opacity: "0",
        duration: 0.75,
      });
      // t2.to("#mainSCR", {
      //   yPercent: "-100",
      //   duration: 1,
      // });
    });
    return () => ctx.revert();
  }, []);

  return (
    <div
      id="mainSCR"
      className="h-dvh relative snap-start overflow-hidden overflow-x-hidden"
    >
      <h3 className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 font-bold text-xl md:text-3xl mx-auto text-center bg-cream px-6 text-blax z-30">
        ˗ˏˋ ꒰ ★ ꒱ ˎˊ˗ <br />
        ˗ˏˋ bygretya ˎˊ˗
      </h3>
      <div
        id="logo-container"
        className="absolute left-1/2 bottom-0 -translate-x-1/2 m-6 p-3 text-center mx-auto z-20"
      >
        <h1 className="bg-cream z-20 m-6" id="logo">
          scroll down please ૮ ˶ᵔ ᵕ ᵔ˶ ა
        </h1>
        <h1 className="bg-cream p-1" id="logo">
          ˗ˏˋ ★ ˎˊ˗
        </h1>
        <h1 className="bg-cream p-1" id="logo">
          -★-
        </h1>
        <h1 className="bg-cream p-1" id="logo">
          ↓
        </h1>
      </div>
      <div className="absolute top-1/2 -translate-x-1/2 right-0 rotate-90">
        <h1 className="text-xl" id="bgr">
          @bygretya
        </h1>
      </div>
      <div
        id="text-holder"
        className="absolute bottom-0 left-0 m-16 p-3 text-blax text-3xl font-thin"
      >
        <h1 id="bgr">O O O O O O </h1>
        <h1 id="bgr">O O O O O O </h1>
        <h1 id="bgr">O O O O O O </h1>
        <h1 id="bgr">O O O O O O </h1>
        <h1 id="bgr">O O O O O O </h1>
        <h1 id="bgr">O O O O O O </h1>
        <h1 id="bgr">O O O O O O </h1>
        <h1 id="bgr">O O O O O O </h1>
        <h1 id="bgr">O O O O O O </h1>
        <h1 id="bgr">O O O O O O </h1>
      </div>
    </div>
  );
}

export default Enter;
